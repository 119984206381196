import ClientOnly from 'vue-client-only'
import { Panel } from '@common/panel'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent])

export const ChartsDoughnut = {
  mixins: [Panel],

  props: {
    link: {
      type: Object,
      default: null,
    },
    width: {
      type: String,
      default: '318px',
    },
    height: {
      type: String,
      default: '155px',
    },
  },

  components: {
    ClientOnly,
    VChart,
  },

  provide: {
    [THEME_KEY]: 'light',
  },
}
