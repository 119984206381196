<template lang="pug">
  main#main.manager-dashboard
    b-container(fluid)
      wc-breadcrumb(icon='fal fa-tachometer')
      section.l-section
        .l-section-h
          b-row(cols=1 cols-md=2 cols-lg=3 cols-xl=3)
            b-col.mb-5(v-if='isAM')
              panel-accounts(
                :link="{ name: 'manager-accounts' }")

            b-col.mb-5(v-if='isAMT')
              panel-organizations(
                :link="{ name: 'manager-organizations' }")

            b-col.mb-5(v-if='isAMT')
              panel-machines(
                :link="{ name: 'manager-machines' }")

            b-col.mb-5(v-if='isAMT')
              panel-firmwares(
                :link="{ name: 'manager-firmwares' }")

            b-col.mb-5(v-if='isAMT')
              panel-machines-online(
                :link="{ name: 'manager-machines' }")

            b-col.mb-5
              panel-current-model(ref='panelCurrentModel')

</template>

<script>
import WcBreadcrumb from '@components/shared/WcBreadcrumb'
import PanelCurrentModel from '@components/layouts/shared/PanelCurrentModel'
import PanelAccounts from '@components/layouts/manager/dashboard/PanelAccounts'
import PanelOrganizations from '@components/layouts/manager/dashboard/PanelOrganizations'
import PanelMachines from '@components/layouts/manager/dashboard/PanelMachines'
import PanelFirmwares from '@components/layouts/manager/dashboard/PanelFirmwares'
import PanelMachinesOnline from '@components/layouts/manager/dashboard/PanelMachinesOnline'

export default {
  name: 'manager-dashboard',
  components: {
    WcBreadcrumb,
    PanelCurrentModel,
    PanelAccounts,
    PanelOrganizations,
    PanelMachines,
    PanelFirmwares,
    PanelMachinesOnline,
  },
  computed: {
    isAM() {
      return this.$store.getters['auth/isAM']
    },
    isAMT() {
      return this.$store.getters['auth/isAMT']
    },
  },
}
</script>
