<template lang="pug">
  transition(name='fade')
    b-card.lm-dashboard-panel-machines.card-white.card-no-gutters.shadow(no-body)
      b-card-header.bg-transparent.border-0
        h5.m-0
          b-link.text-body(:to='link' :disabled='!link')
            i.fa-fw.fal.fa-conveyor-belt-alt
            |  {{ $t('activerecord.models.machine.other') }}
      b-card-body.d-flex.justify-content-center.align-items-center.pt-0
        .chart-wrapper(:style='`width: ${width}; height: ${height};`')
          client-only
            v-chart.chart(:option='chart')
</template>

<script>
import { ChartsDoughnut } from '@common/charts/doughnut'
import approx from 'approximate-number'

export default {
  name: 'lm-dashboard-panel-machines',
  mixins: [ChartsDoughnut],
  watch: {
    'panel.data'() {
      this.chart.title.text = `${approx(this.panel.data.attributes.total, {
        separator: this.$t('number.format.delimiter'),
        decimal: this.$t('number.format.separator'),
      })}\n${this.$t('shared.basic.total')}`
      this.chart.series[0].data = this.panel.data.attributes.chart
    },
  },
  data() {
    return {
      cacheType: 'manager/dashboard/getPanelMachines',
      cacheOptions: { timeout: this.$wc.conf.cache.charts },
      skipNotifications: true,
      chart: {
        textStyle: {
          fontFamily: 'Montserrat,Helvetica Neue,Calibri,Arial,sans-serif',
        },
        title: {
          text: `0\n${this.$t('shared.basic.total')}`,
          top: '37%',
          left: '73%',
          textAlign: 'center',
          textStyle: {
            fontSize: '1rem',
            fontWeight: 'normal',
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            const key = params.data.name.startsWith('basic.')
              ? `shared.${params.data.name}`
              : `${this.panel.data.attributes.collection}.${params.data.name}`
            return `<center><b>${approx(params.data.value, {
              separator: this.$t('number.format.delimiter'),
              decimal: this.$t('number.format.separator'),
            })}</b>\n${this.$t(key)}<br /># ${params.data.value.toLocaleString(
              this.$wc.shared.locale
            )}</center>`
          },
        },
        legend: {
          top: '0',
          left: '0',
          orient: 'vertical',
          itemWidth: 10,
          itemHeight: 10,
          formatter: (name) => {
            return name.startsWith('basic.')
              ? this.$t(`shared.${name}`)
              : this.panel.data.attributes
              ? this.$t(`${this.panel.data.attributes.collection}.${name}`)
              : name
          },
        },
        series: [
          {
            name: this.$t('activerecord.models.machine.other'),
            type: 'pie',
            radius: ['75%', '100%'],
            center: ['75%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: '#fff',
              borderWidth: 2,
              color: (params) => {
                return this.$getDeep(this.panel.data.attributes.colors, params.data.name)
              },
            },
            label: {
              show: false,
              position: 'center',
              fontSize: '1rem',
              formatter: (params) => {
                const key = params.data.name.startsWith('basic.')
                  ? `shared.${params.data.name}`
                  : `${this.panel.data.attributes.collection}.${params.data.name}`
                return `${approx(params.data.value, {
                  separator: this.$t('number.format.delimiter'),
                  decimal: this.$t('number.format.separator'),
                })}\n${this.$t(key)}`
              },
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              scale: false,
            },
            data: [],
          },
        ],
      },
    }
  },
}
</script>
